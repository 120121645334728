.hero{
  padding-top: 70px;
  background-color: #eb6572!important;
  margin-top: -70px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  /* box-shadow: 1px 1px 20px #888888; */
}

.stat-box, .btn{
  -webkit-box-shadow: 3px 6px 16px -3px rgba(179,175,179,1);
  -moz-box-shadow: 3px 6px 16px -3px rgba(179,175,179,1);
  box-shadow: 3px 6px 16px -3px rgba(179,175,179,1);
}

.contain {
  background-color: #ffffff;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  /* background-image:url("../../../image/login_icon.jpg"); */
  background-color: whitesmoke;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex-direction: column;
}
.form_contain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  min-width: 800px;
  margin-bottom: 20px;
}
.title_name {
  color: #eb6572;
  font-size: 64px;
  margin-bottom: 50px;
}
.form_title {
  color: #ffffff;
  margin-top: 50px;
  padding-bottom: 50px;
}
.form {
  width: 45%;
}
.form-control {
  font-size: 24px;
  /* border-radius: 10px; */
}

.btn {
  /* border-radius: 20px; */
}
.in-valid{
  border-color: #eb6572!important;
}
body{
  background-color: rgb(242, 238, 238)!important;
}

.exampleModalLabel{
background-color:yellow !important;
}

.hd{
  text-align: center !important;
}