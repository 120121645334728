:root {
  --bs-blue: #132cac;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #f90000;
  --bs-orange: #fc5400;
  --bs-yellow: #f16a1b;
  --bs-green: #02c055;
  --bs-teal: #20c997;
  --bs-cyan: #08b1ba;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #28353d;
  --bs-secondary: #6c757d;
  --bs-success: #1aa053;
  --bs-info: #08b1ba;
  --bs-warning: #f94904;
  --bs-danger: #f90000;
  --bs-light: #dee2e6;
  --bs-dark: #212529;
  --bs-primary-rgb: 58, 87, 232;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 26, 160, 83;
  --bs-info-rgb: 8, 177, 186;
  --bs-warning-rgb: 241, 106, 27;
  --bs-danger-rgb: 192, 50, 33;
  --bs-light-rgb: 222, 226, 230;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 138, 146, 166;
  --bs-body-bg-rgb: 249, 249, 249;
  --bs-font-sans-serif: "Inter", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #8a92a6;
  --bs-body-bg: #f9f9f9;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #eee;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.125rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #e6e2ca;
  --bs-link-hover-color: #e6e2ca;
  --bs-code-color: #ddd976;
  --bs-highlight-bg: #fce1d1;
}
.App {
  text-align: center;
}
.bg-Nav {
  background-color: #28353d !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
table th {
  background: #2c363c !important;
  color: white !important;
}
.nx-confirm-button-ok {
  background: #28353d !important;
  color: white !important;
}
.notiflix-confirm-head h5 {
  color: #28353d !important;
}

.fieldset,
.legend {
  all: revert;
  border-radius: 5px;
  border: 1px solid #28353d;
  padding: 10px;
  margin: 5px;
}
.legend {
  font-size: 1.2em;
  background-color: var(--bs-primary);
  color: white;
  padding: 5px 10px;
}

.hvr-shutter-out-horizontal:hover {
  color: white !important;
}

.nav-item .nav-link {
  color: white !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

// active
.active {
  background-color: #28353d !important;
  color: white !important;
  border-radius: 7px;
  h5 {
    color: white !important;
  }
}

/* layout Navbar */
.app-layouts {
  display: flex;
  flex-direction: column;
  flex: auto 1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  .app-content {
    background-color: #dee2e6;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
    padding: 1rem;
  }
}

.bg-hover:hover,
.bg-hover:focus {
  color: white;
  background-color: #28353d;
  h5 {
    color: white;
  }
}

.active {
  background-color: #28353d !important;
  color: white !important;
}
.activeNav {
  border-bottom: 3px solid #28353d !important;
  color: white !important;
  background-color: #626262;
  padding-top: 10px !important;
  margin-top: -10px!important;
  margin-bottom: -10px!important;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.form-control {
  font-size: 16px !important;
}

.btn {
  box-shadow: none !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
  padding: 5px!important;
}
.btn-m {
  padding: 0.2rem 1rem !important;
  font-size: 1rem !important;
  // background-color: var(--bs-success) !important;
  color: white !important;
}
.m-top {
  margin-top: -10px !important;
}
.nav-link {
  font-size: 18px !important;
  span {
    font-size: 18px !important;
  }
}
.dropdown-menu {
  font-size: 18px !important;
  li {
    font-size: 18px !important;
    a {
      font-size: 18px !important;
    }
  }
}
.scoView{
  width: 100% !important;
}

/* Zoom image */
.img-zoom-hover:hover {
  transform: scale(5);
}

// height
#CustomHeight{
  height: 100% !important;
}
